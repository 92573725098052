import React from 'react'

export default function Contact() {
    return (
        <main>
            <section id="Contact">
                <h1 className="font-extrabold text-5xl text-black m-auto text-center mt-20">Contact</h1>
                <div className="flex flex-col md:flex-row w-[90%] justify-center m-auto  pt-10 md:space-x-28">
                    <div className='my-6 md:my-0'><img src="/resources/Screenshot 2023-11-22 203706-c4dc5868.png" className="h-12 lg:h-16 m-auto cursor-pointer grayscale hover:grayscale-0" alt="" />
                        <p className="text-center mt-5"><b>Contact no</b>:-<br />9998464854</p>
                    </div>
                    <div className='my-6 md:my-0'><a href="mailto:jsrinfo18@gmail.com"><img src="/resources/Screenshot 2023-11-22 203816-0eb7b323.png" className="h-9 lg:h-12 lg:mt-4 m-auto cursor-pointer grayscale hover:grayscale-0" alt="" /><p className="text-center mt-5"><b>Email Us</b>:-<br />jsrinfo18@gmail.com</p>
                    </a>
                    </div>
                    <div className='my-6 md:my-0'><img src="/resources/Screenshot 2023-11-22 203947-8508f096.png" className="h-12 lg:h-16 m-auto cursor-pointer grayscale hover:grayscale-0" alt="" />
                        <p className="text-center mt-[18px]"><b>Social</b><br /></p>
                        <div className="flex m-auto social justify-center items-center">
                            <a href="https://youtube.com/@tallymodule2.0" target='_blank'><img src="/resources/yt.svg" alt="" className="h-8 cursor-pointer grayscale hover:grayscale-0" /></a>
                            <a href="https://wa.me/+919998464854" target='_blank'><img src="/resources/Screenshot 2023-11-22 213057-fc3c8af7.png" alt="" className="h-8 cursor-pointer grayscale hover:grayscale-0" /></a>
                            <a href="https://www.facebook.com/share/GCBQF(ZiJoUjF4mn/?mibextid=qi20mg" target='_blank'><img src="/resources/facebook.svg" alt="" className="h-[2.75rem] ml-2 cursor-pointer grayscale hover:grayscale-0" /></a>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
