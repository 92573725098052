import React from 'react';
import { Link } from 'react-router-dom';

export default function Form() {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="w-full max-w-md bg-gray-50 rounded-lg shadow-lg p-8">
        <Link to="/Solutions" className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 transition duration-300">X</Link>
        <h2 className="text-2xl font-semibold text-center mb-6">Order Form</h2>
        <form action="https://formspree.io/f/xknlbora" method="POST">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="companyName">Company Name</label>
            <input id="companyName" name="companyName" type="text" className="w-full px-4 py-2 rounded border focus:outline-none focus:border-blue-500" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Contact Person *</label>
            <input id="name" name="name" type="text" className="w-full px-4 py-2 rounded border focus:outline-none focus:border-blue-500" required />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">Mobile No. *</label>
            <input id="phone" name="phone" type="tel" className="w-full px-4 py-2 rounded border focus:outline-none focus:border-blue-500" required />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
            <input id="email" name="email" type="email" className="w-full px-4 py-2 rounded border focus:outline-none focus:border-blue-500" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gstin">Tally License No.</label>
            <input id="gstin" name="gstin" type="text" className="w-full px-4 py-2 rounded border focus:outline-none focus:border-blue-500"  />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Remark</label>
            <input id="name" name="name" type="text" className="w-full px-4 py-2 rounded border focus:outline-none focus:border-blue-500" />
          </div>
          <div className="text-center">
            <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}
