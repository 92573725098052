import React from 'react';

export default function Clients() {
  // Generate an array of numbers from 0 to 9
  const logos = Array.from({ length: 50 }, (_, i) => i);

  // Function to check if the image exists
  const imageExists = (url) => {
    const img = new Image();
    img.src = url;
    return img.complete;
  };

  return (
    <div>
      <div className='bg-[rgb(248,248,248)] pb-20 pt-10 mt-20'>
        <h1 className='font-extrabold text-5xl w-fit m-auto mb-10'>Our Client</h1>
        <div className='grid md:grid-cols-2 lg:grid-cols-6 justify-center items-center w-[90vw] m-auto'>
          {logos
            .filter((index) => imageExists(`resources/logo${index}.png`)) // Filter out images that don't exist
            .map((index) => (
              <img
                key={index}
                className='h-28 cursor-pointer m-auto my-5 scale-[0.78]'
                src={`resources/logo${index}.png`}
                alt=""
              />
            ))}
        </div>
      </div>
    </div>
  );
}
